//https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
import React, { useEffect, useRef } from "react";
// import mysql from 'mysql2';
// import dbconn from '../db';

// let analytics;
// if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
//     analytics = getAnalytics(app);
// }

type AppContextType = {
    session: {};
    // db: mysql.Connection;
}
const initial = { session: {} };
const AppContext = React.createContext<AppContextType>(initial);

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
    const didRunRef = useRef(false);

    useEffect(() => {
        if (didRunRef.current === false) {
            didRunRef.current = true;
            console.log('AppContext');
            const getIp = async () => {
                //   // -- ip address --
                //   // https://api.ipify.org/
                //   // https://ip4.seeip.org/ (CORS)
                //   // const data = await (await fetch("https://api.ipify.org")).text();

                //   // -- ip + geo --
                //   // https://ipapi.co/json/
                //   // http://ip-api.com/json/
                const data = await(await fetch("https://ipapi.co/json/")).json();
                fetch('/api/addClientInfo', {
                    method: "POST",
                    body: JSON.stringify(data)
                });
            }
            getIp();

            
        }
    }, []);

    return (
        <AppContext.Provider value={{ session: {} }}>
            {children}
        </AppContext.Provider>
    );
}

export { AppContext, AppContextProvider };
